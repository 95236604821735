













































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
// @ts-ignore
import EasyCamera from 'easy-vue-camera'
import ContainerCard from '@/components/ContainerCard.vue'

import RegisterCompany from '@/graphql/companyService/RegisterCompany.gql'
import { IRegisterCompanyData } from '@/graphql/companyService/interfaces/registerCompany.interface'
import { parseUrl } from '@/lib/parse-service'
import http from 'ky'

@Component<RegisterForm>({
  components: {
    ContainerCard,
    'v-easy-camera': EasyCamera,
  },
})
export default class RegisterForm extends Vue {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>
  }
  newCompany = {
    name: '',
    contactPerson: '',
    phone: '',
    email: '',
    zip: '',
    street: '',
    country: '',
    city: '',
    recipient: '',
    iban: '',
    bic: ''
  }
  takePicture = false
  termsChecked = false
  file = null
  cameraPicture = null

  // @ts-ignore
  selectedFile(event) {
    // @ts-ignore
    this.file = event.target.files[0];
    this.cameraPicture = null;
  }

  async uploadVerificationFile(): Promise<any> {
    // @ts-ignore
    if(!this.cameraPicture && !this.file) return null;
    const body = new FormData();
    // @ts-ignore
    body.append('file', this.cameraPicture ? this.cameraPicture : this.file);
    return (await http.post(`${parseUrl}/registration-upload`, {
      body,
    })).text()
  }

  async submit() {
    const valid = await this.$refs.observer.validate()
    if (!valid) { return }
    try {
      const verificationFileId = await this.uploadVerificationFile();
      const { data } = await this.$apollo.mutate<IRegisterCompanyData>({
        mutation: RegisterCompany,
        variables: {
          ...this.newCompany,
          verificationFileId,
        }
      })
      if (data && data.registerNewCompany === 'success') {
        this.$emit('advance')
      } else {
        this.$toast.open({ type: 'error', message: `${this.$t('toast.Something went wrong')}` })
      }
    } catch (error) {
      this.$toast.open({ type: 'error', message: `${this.$t('toast.errors.' + error.message)}` })
    }
  }
}
